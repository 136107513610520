import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { ComponentLinks, FigmaEmbed } from '../common';
import { UserMenu, UserMenuList, UserMenuListItem, IconActionLogout, IconGlobalSettings } from '@hse-design/react';
import { Playground, Props } from 'docz';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "usermenu"
    }}>{`UserMenu`}</h1>
    <ComponentLinks name="UserMenu" storybook="/?path=/story/userMenu-userMenu--playground" vueStorybook="/?path=/story/userMenu-userMenu--playground" figma="https://www.figma.com/file/cBHD57QcCt9WDT4e7e2B3w/hse_general_library?node-id=31887%3A40359" mdxType="ComponentLinks" />
    <h2 {...{
      "id": "описание"
    }}>{`Описание`}</h2>
    <p>{`UserMenu – компонент для отображения текущего аккаунта пользователя и списка возможных действий. Компонент предполагается использовать в правой части компонента Header`}</p>
    <p>{`UserMenu представляет собой окно, отображающее текущую учетную запись, её настройки и возможность выхода из неё`}</p>
    <p>{`В качестве children необходимо передать компонент `}<inlineCode parentName="p">{`UserMenuList`}</inlineCode>{` внутри которого нужно использовать компоненты `}<inlineCode parentName="p">{`UserMenuListItem`}</inlineCode>{` для пунктов меню.`}</p>
    <Playground __position={1} __code={'() => {\n  const props = {\n    src:\n      \'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png\',\n    alt: \'Person\',\n    name: \'Христорождественская Александрина К.\',\n    role: \'Администратор\',\n  }\n  const menu = [\n    {\n      id: 1,\n      name: \'Настройки\',\n      href: \'javascript:void(0)\',\n      icon: IconGlobalSettings,\n    },\n    {\n      id: 2,\n      name: \'Выход\',\n      href: \'javascript:void(0)\',\n      icon: IconActionLogout,\n    },\n  ]\n  return (\n    <UserMenu {...props}>\n      <UserMenuList>\n        {menu.map(item => (\n          <UserMenuListItem key={item.id} href={item.to} icon={item.icon}>\n            {item.name}\n          </UserMenuListItem>\n        ))}\n      </UserMenuList>\n    </UserMenu>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      ComponentLinks,
      FigmaEmbed,
      UserMenu,
      UserMenuList,
      UserMenuListItem,
      IconActionLogout,
      IconGlobalSettings,
      Playground,
      Props,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
	{() => {
        const props = {
          src: 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png',
          alt: 'Person',
          name: 'Христорождественская Александрина К.',
          role: 'Администратор'
        };
        const menu = [{
          id: 1,
          name: 'Настройки',
          href: 'javascript:void(0)',
          icon: IconGlobalSettings
        }, {
          id: 2,
          name: 'Выход',
          href: 'javascript:void(0)',
          icon: IconActionLogout
        }];
        return <UserMenu {...props} mdxType="UserMenu">
	      <UserMenuList mdxType="UserMenuList">
	        {menu.map(item => <UserMenuListItem key={item.id} href={item.to} icon={item.icon} mdxType="UserMenuListItem">
	            {item.name}
	          </UserMenuListItem>)}
	      </UserMenuList>
	    </UserMenu>;
      }}
    </Playground>
    <FigmaEmbed node='?node-id=31887%3A40487' mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "поведение"
    }}>{`Поведение`}</h2>
    <FigmaEmbed node='?node-id=31887%3A40368' mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "анатомия"
    }}>{`Анатомия`}</h2>
    <FigmaEmbed node='?node-id=31887%3A40374' mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "использование"
    }}>{`Использование`}</h2>
    <FigmaEmbed node='?node-id=31887%3A40399' mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "пропы-usermenu"
    }}>{`Пропы UserMenu`}</h2>
    <Props of={UserMenu} mdxType="Props" />
    <h2 {...{
      "id": "пропы-usermenulist"
    }}>{`Пропы UserMenuList`}</h2>
    <Props of={UserMenuList} mdxType="Props" />
    <h2 {...{
      "id": "пропы-usermenulistitem"
    }}>{`Пропы UserMenuListItem`}</h2>
    <Props of={UserMenuListItem} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      